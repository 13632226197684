.loader-main {
  position: fixed;
  inset: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
}
.loader-main .loader-img {
  animation: blink 1s linear infinite alternate;
  transition: all ease-in-out 0.5s;
}
@keyframes blink {
  0% {
      opacity: 1;
      transform: scale(1);
  }
  25% {
      opacity: 0.8;
      transform: scale(0.9);
  }
  50% {
      opacity: 0.5;
      transform: scale(0.8);
  }
  75% {
      opacity: 0.8;
      transform: scale(0.9);
  }
  75% {
      opacity: 1;
      transform: scale(1);
  }
}

select.selectpicker option {
  display: inline-flex !important; 
  column-gap: 10px  !important; 
  padding-right: 10px  !important; 
  margin-right: 10px !important;
  border-radius: 5px;
  padding: 5px 10px;
}
select.selectpicker {
  min-width: 50%  !important; ;
}
/* select:-internal-list-box option:checked {
  background-color: -internal-light-dark(green, green) !important;
  color: -internal-light-dark(rgb(16, 16, 16), blue);
} */
select[multiple]:focus option:checked {
  background: rgb(114, 255, 114) linear-gradient(0deg,  #ddd 0%,  #ddd 100%);
  color:#ffffff !important;
  box-shadow: 0 0 0 3px #ccc !important;
  outline: 1px solid blue !important;
}

.code-input{
  position: relative;
}
.code-input .btn-success{
  position: absolute;
  right:0;
  top: 0;
  margin: 0 !important;
  padding: 7px;
}
.select-subject .subjects{
  width: 25%;
  margin: 10px 0;
}
.subject-add{
  position: relative;
}
.subject-add .btn-outline-primary{
  position: absolute;
  right: 0;
  top: 0;
}


.text-wrap-recent{
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 4 !important;

}

.leaflet-container{
height: 80vh;
width: 100%;
}


/* Tooltip Design */
.tooltip-green-border .tooltip-inner {
background-color: #28a745 !important; /* Green background color */
border: 1px solid #218838  !important; /* Darker green border color */
}


/* Marker */

.marker {
font-size: 24px;
color: red;
}

.popup {
position: absolute;
background: white;
padding: 10px;
border: 1px solid #ccc;
z-index: 100;
}

.close-btn {
cursor: pointer;
position: absolute;
top: 5px;
right: 5px;
}

.swal-footer {
  text-align: center !important;
}

/* .my-tooltip-btn:hover{
background-color: blue;


border-radius: 50%;
} */


/* swal  cancel btn   */
.Custom_Cancel > .sa-button-container > .cancel {
background-color: #DD6B55;
border-color: #DD6B55;
}
.Custom_Cancel > .sa-button-container > .cancel:hover {
background-color: #DD6B55;
border-color: #DD6B55;
}

/* 11-12-23 */
.userDtls {
display: flex;
align-items: center;
gap: 10px;
background: #00b1ec none repeat scroll 0 0;
color: #fff;
float: left;
min-height: 50px;
min-width: 100%;
text-align: center;
vertical-align: middle;
width:100%;
padding:2px 15px;

}
.eserWrap{
background-color:#fff;
}
.eserWrap svg {
border: 1px solid #fff;
padding: 5px;
font-size: 30px;
}
.userDtlsList{
display: flex;
border-bottom: 1px solid #ccc;
padding-bottom: 10px;
align-items: center;
}
.userDtlsList p{
margin-bottom: 0;
}
.userDtlsList svg {
background: #fff none repeat scroll 0 0;
color: #00b1ec;
float: left;
height: 33px;
min-height: 21px;
min-width: 50px;
text-align: center;
vertical-align: middle;
width: 40px;
margin-right: 5px;
}
.sidebar {
/* background: #00b1ec; */
background: #008fbe;

}
.sidebar-nav .nav-link {
color: #44d4ff;
}
img.sidebar-brand-full {
  height: 149px;
}
.form-check-input:checked {
  background-color: #00b1ec;
  border-color: #00b3e9;
}

.eserWrap .form-switch .form-check-input {
  margin-left: -1.5em !important;
}
.breadcrumb-item a {
  color: #026485;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
}
.table-responsive svg {
  font-size: 18px;
  color: #00b1ec;
}
.table-light {
  --cui-table-color: rgb(255 255 255 / 95%);
  --cui-table-bg: #00749a;
  color: #fff;
}
.table-hover > tbody > tr:hover > * {
  --cui-table-color-state: var(
--cui-table-hover-color);
  --cui-table-bg-state: rgb(0 177 236 / 8%);
}
.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #0091bb;
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0 #007598;
}
.table-responsive .bg-secondary {
  background-color: #0091bb !important;
}
.table-responsive i {
  color: #00b1ec;
  font-size: 18px;
}
a.link-underline small {
  color: #0091bb;
  font-weight: 600;
}
.link-underline-opacity-75-hover {
  font-size: 14px;
}
.btn-primary {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #00b1ec;
  --cui-btn-border-color: #00b1ec;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #00b7f3;
  --cui-btn-hover-border-color: #00b3e9;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #00b3e9;
  --cui-btn-active-border-color: #00b3e9;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #00b3e9;
  --cui-btn-disabled-border-color: #00b3e9;
}
i.fa-solid.fa-trash {
  color: #c70000;
}
.bg-dark {
  background-color: #0091bb;
}
.bg-light {
  background-color: #f3f8f9;
}
.sidebar-nav svg.nav-icon {
  overflow: hidden;
  color: #a8ebff;
}
.sidebar-nav .nav-link.active {
  background: #00b1ec;
}
.sidebar-nav .nav-link:hover {
  padding-left: 10px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}